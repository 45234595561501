$(document).ready(function () {
    if ($(window).width() > 991) {
        var stickyBlock = $('.aside .inside > .block:last-child');
        $(window).scroll(function (e) {
            var width = stickyBlock.width();
            var sidebarCheck = $('.aside').outerHeight() + 120;
            var winScroll = $(window).scrollTop();

            if (winScroll >= sidebarCheck) {
                if (stickyBlock.css("position") !== 'fixed') {
                    stickyBlock
                        .css({position: 'fixed', top: 50, width: width, opacity: 0, zIndex: 1000})
                        .animate({opacity: 1});
                }
            } else {
                if (stickyBlock.css("position") === 'fixed') {
                    stickyBlock.css({position: 'relative', top: 'initial', width: width, opacity: 1, zIndex: 'initial'})
                }
            }
        });
    }
});
