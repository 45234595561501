var timeout = false;
$(document).ready(function () {
    Utils.Validation.getOrSetupFormValidation(
        "#newsletterSignupFormSidebar",
        {
            email: {
                required: true,
                email: true
            }
        },
        function (form) {

            $("#newsletterSignupFormSidebar")
                .find('.btn')
                .makeLoading({append: true});

            if (window.newsletterPageSignup) {
                EventTracking.track(window.newsletterPageSignup, 'clicked', window.location.href, 1);
            }

            $.ajax({
                url: '/newsletter-sign-up',
                method: 'post',
                headers: {'X-CSRF-TOKEN': getCsrfToken()},
                dataType: 'json',
                data: $(form).serialize(),
                success: function (data) {
                    $('.newsletter.newsletterSignupWrapper').hide();
                    $("#newsletterSignupFormSidebar").find('.btn').unmakeLoading();

                    if (data.logged_in === true) {
                        $('.newsletter.newsletterSignupConfirm .btn-complete-profile').hide();
                        $('.newsletter.newsletterSignupConfirm').slideDown(300, 'easeOutQuad');
                        return;
                    }

                    $('.newsletter.newsletterSignupConfirm').find('.btn').hide();
                    $('.newsletter.newsletterSignupConfirm').slideDown(300, 'easeOutQuad');
                }
            });
        },
        {
            email: {
                email: "Please enter a valid email address",
                required: "Please enter your email",
            },
        }
    );
});