$(document).ready(function (){
    if (('localStorage' in window) && window['localStorage'] !== null) {
        if (document.URL in localStorage) {
            $(".loadMoreContent").html(localStorage.getItem(document.URL));
        }
    }

    $(window).scroll(function() {
        if($(window).scrollTop() + $(window).height() > $(document).height() - 150) {
            if (!$('.loadMore').hasClass('disable')) {

                var loadMore = $('.loadMore');
                var loadMoreContent = $('.loadMoreContent');

                loadMore.makeLoading({append: true});
                loadMore.addClass('disable');

                var offset = loadMore.attr('data-offset');
                var url = loadMore.attr('data-more-url');

                $.ajax({
                    url: url + "&offset=" + offset,
                    method: 'get',
                    dataType: 'json',
                    success: function (data) {
                        if (data.status) {
                            //load events to event div
                            loadMore.removeClass('disable');
                            loadMore.attr('data-offset', data.new_offset);
                            loadMoreContent.append(data.partial);
                        } else {
                            $('span', loadMore).html('NO MORE STUFF')
                        }

                        // Restore the Load-more to it's original state
                        loadMore.unmakeLoading();

                        history.replaceState({}, document.title, '#story-block-' + offset);

                        if (('localStorage' in window) && window['localStorage'] !== null) {
                            var storageKey = document.URL;
                            storageKey = storageKey.substr(0, storageKey.indexOf('#'));
                            storageKey = storageKey + '#story-block-' + offset;
                            localStorage.setItem(storageKey, loadMoreContent.html());
                        }

                    }
                });
            }
        }
    });
});